import { Fetcher } from '@this/src/util';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/components/shared/ui/inputs/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { MessageLogArgs } from '@this/domain/arrangement/message_log';
import { MessageLog } from '@this/domain/arrangement/message_log';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';

interface Props {
  traceId: number;
  onClose: () => void;
}

interface MessageLogResponse {
  logs: MessageLogArgs[];
}

const LogModal = observer(({ traceId, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageLogs, setMessageLogs] = useState<MessageLog[]>([]);
  const [messageLog, setMessageLog] = useState<string>('');

  const fetchLogs = () => {
    setIsLoading(true);
    Fetcher.get<MessageLogResponse>(`/arrangement/order_item/${traceId}/message_logs.json`)
      .then(result => setMessageLogs(result.logs.map((args: MessageLogArgs) => new MessageLog(args))))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchLogs();
  }, [traceId]);

  const saveLog = () => {
    const params = {
      message_log: messageLog,
      trace_id: traceId
    };
    Fetcher.put(`/arrangement/order_item/${traceId}/create_message_log.json`, params)
      .then(() => {
        fetchLogs();
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('メッセージを保存できませんでした。');
      });
  };

  return (
    <Modal open onClose={onClose} size="large">
      <ModalHeader>この商品に関するやり取り</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell nowrap>手配者名</TableCell>
                <TableCell nowrap>内容</TableCell>
                <TableCell nowrap>記載日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messageLogs.map(log => {
                return (
                  <TableRow key={log.createdAt}>
                    <TableCell nowrap>{log.arrangerName}</TableCell>
                    <TableCell>{log.body}</TableCell>
                    <TableCell nowrap>{moment(log.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <input
          type="text"
          placeholder="メッセージ"
          value={messageLog}
          onChange={e => setMessageLog(e.target.value)}
        />
        <div style={{ width: '50px' }}>
          <Button onClick={() => saveLog()}>送信</Button>
        </div>
      </ModalBody>
    </Modal>
  );
});

export default LogModal;
