export const OrderItemCategory = {
  message: 'メッセージ',
  0: 'ホテル',
  1: '国内航空券',
  2: '海外航空券',
  3: '新幹線',
  4: '特急',
  5: '在来線',
  6: 'レンタカー',
  7: 'WBFパッケージ',
  8: 'キャンセル',
  9: 'その他',
  10: '配送料',
  11: 'WiFi',
  12: '会議室',
  13: 'バス',
  14: 'FPパッケージ',
  15: 'JRパッケージ',
  16: 'ANA旅作',
  17: '楽天パッケージ',
  18: 'マイナビパッケージ',
  19: 'リクエストフォーム',
  20: '出張サポート費',
  21: '検査キット',
  22: '変更手数料',
  23: '査証・渡航認証関連',
  24: '保険'
} as const;

export type OrderItemCategoryKey = keyof typeof OrderItemCategory;

export const OrderItemCategoryKeys: readonly OrderItemCategoryKey[] = [
  'message',
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  13,
  10,
  11,
  12,
  15,
  16,
  17,
  21,
  22,
  23,
  24,
  19,
  8,
  9
];
