import styled from 'styled-components';
import SCButton from '@this/components/organization/button';
import React from 'react';
import { isMoment } from 'moment';

type Props = {
  serviceId: number;
  params: { [k in string]: any };
};

type CsvEncodeType = 'utf8' | 'sjis';

const createQueryParamsString = (params: { [k in string]: any }) =>
  Object.entries(params)
    .map(([k, v]) => {
      const value = isMoment(v) ? v.format('YYYY-MM-DD') : v;
      // キャメルケースをスネークケースに
      let key = k.replace(/([A-Z])/g, s => `_${s.charAt(0).toLocaleLowerCase()}`);
      // from_date, to_dateをそれぞれ、form, toに変換する
      key = key.replace(/_date/, '');
      return `${key}=${value}`;
    })
    .join('&');

export const ExicSeisanTripCsvDownloadButton = ({ serviceId, params }: Props) => {
  const [encoding, setEncode] = React.useState<CsvEncodeType>(
    navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8'
  );

  const [disabled, setDisabled] = React.useState(false);

  return (
    <Wrapper>
      <select value={encoding} onChange={e => setEncode(e.target.value as CsvEncodeType)}>
        <option value="utf8">Macなど（文字コード：UTF8）</option>
        <option value="sjis">Windows（文字コード：SJIS）</option>
      </select>
      <SCButton
        serviceId={serviceId}
        value="CSVでダウンロード"
        tag="a"
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          location.href = `/organization/exic_seisan_trips/download_csv?${createQueryParamsString({
            ...params,
            encoding
          })}`;
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > select {
    margin-bottom: 0;
    margin-right: 8px;
  }
`;
