import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

export type SimulateBaseRecordStatus = 'stoped' | 'queued' | 'run' | 'failed' | 'completed';
export type SimulateBaseRecordType =
  | 'hotel'
  | 'domestic_air'
  | 'foreign_air'
  | 'shinkansen'
  | 'express'
  | 'railway_ticket'
  | 'rental_car'
  | 'wbf_package'
  | 'cancel'
  | 'change_fee'
  | 'other'
  | 'shipping'
  | 'wifi'
  | 'room'
  | 'bus'
  | 'fp_package'
  | 'jr_package'
  | 'ana_tabisaku'
  | 'rakuten_package'
  | 'mynavi_package'
  | 'arrangement_request'
  | 'support_fee'
  | 'kit'
  | 'travel_authorization'
  | 'insurance';

const STATUSES: Record<SimulateBaseRecordStatus, string> = {
  stoped: '停止中',
  queued: '検索待ち',
  run: '検索中',
  failed: '失敗',
  completed: '成功'
};

export interface SimulateBaseRecordArgs {
  id: number;
  row?: number;
  simulate_base_file_id: number;
  record_type: SimulateBaseRecordType;
  status: SimulateBaseRecordStatus;
  reserved_at: string;
  start_at: string;
  end_at: string;
  price_with_tax: number;
  people_num: number;
  room_num: number;
  stay_num?: number;
  lead_time?: number;
  item_name: string;
  traveler?: string;
  traveler_romen?: string;
  traveler_code?: string;
  arranger?: string;
  arranger_roman?: string;
  arranger_code?: string;
  message?: string;
  ait_same_item_id?: string;
  ait_same_item_hotel_id?: number;
  ait_same_item_price?: number;
  ait_same_item_name?: string;
  ait_cheapest_item_id?: string;
  ait_cheapest_item_hotel_id?: number;
  ait_cheapest_item_price?: number;
  ait_cheapest_item_name?: string;
  ait_recommended_item_id?: string;
  ait_recommended_item_hotel_id?: number;
  ait_recommended_item_price?: number;
  ait_recommended_item_name?: string;
  ait_average_price?: number;
  ait_past_same_item_id?: string;
  ait_past_same_item_hotel_id?: number;
  ait_past_same_item_price?: number;
  ait_past_same_item_name?: string;
  ait_past_cheapest_item_id?: string;
  ait_past_cheapest_item_hotel_id?: number;
  ait_past_cheapest_item_price?: number;
  ait_past_cheapest_item_name?: string;
  ait_past_recommended_item_id?: string;
  ait_past_recommended_item_hotel_id?: number;
  ait_past_recommended_item_price?: number;
  ait_past_recommended_item_name?: string;
  ait_past_average_price?: number;
  csv_index?: number;
  created_at: string;
  updated_at: string;
}

export type PriceField =
  | 'aitSameItemPrice'
  | 'aitCheapestItemPrice'
  | 'aitRecommendedItemPrice'
  | 'aitAveragePrice'
  | 'aitPastSameItemPrice'
  | 'aitPastCheapestItemPrice'
  | 'aitPastRecommendedItemPrice'
  | 'aitPastAveragePrice';
type DiffField =
  | 'aitSameItemDiff'
  | 'aitCheapestItemDiff'
  | 'aitRecommendedItemDiff'
  | 'aitAverageDiff'
  | 'aitPastSameItemDiff'
  | 'aitPastCheapestItemDiff'
  | 'aitPastRecommendedItemDiff'
  | 'aitPastAverageDiff';
type PricePerStayField =
  | 'aitSameItemPricePerStay'
  | 'aitCheapestItemPricePerStay'
  | 'aitRecommendedItemPricePerStay'
  | 'aitAveragePricePerStay'
  | 'aitPastSameItemPricePerStay'
  | 'aitPastCheapestItemPricePerStay'
  | 'aitPastRecommendedItemPricePerStay'
  | 'aitPastAveragePricePerStay';
type DiffPerStayField =
  | 'aitSameItemDiffPerStay'
  | 'aitCheapestItemDiffPerStay'
  | 'aitRecommendedItemDiffPerStay'
  | 'aitAverageDiffPerStay'
  | 'aitPastSameItemDiffPerStay'
  | 'aitPastCheapestItemDiffPerStay'
  | 'aitPastRecommendedItemDiffPerStay'
  | 'aitPastAverageDiffPerStay';
type NameField =
  | 'aitSameItemName'
  | 'aitCheapestItemName'
  | 'aitRecommendedItemName'
  | 'aitPastSameItemName'
  | 'aitPastCheapestItemName'
  | 'aitPastRecommendedItemName';
type HotelIdField =
  | 'aitSameItemHotelId'
  | 'aitCheapestItemHotelId'
  | 'aitRecommendedItemHotelId'
  | 'aitPastSameItemHotelId'
  | 'aitPastCheapestItemHotelId'
  | 'aitPastRecommendedItemHotelId';

const PRICE_TO_DIFF: Record<PriceField, DiffField> = {
  aitSameItemPrice: 'aitSameItemDiff',
  aitCheapestItemPrice: 'aitCheapestItemDiff',
  aitRecommendedItemPrice: 'aitRecommendedItemDiff',
  aitAveragePrice: 'aitAverageDiff',
  aitPastSameItemPrice: 'aitPastSameItemDiff',
  aitPastCheapestItemPrice: 'aitPastCheapestItemDiff',
  aitPastRecommendedItemPrice: 'aitPastRecommendedItemDiff',
  aitPastAveragePrice: 'aitPastAverageDiff'
};

const PRICE_TO_PRICE_PER_STAY: Record<PriceField, PricePerStayField> = {
  aitSameItemPrice: 'aitSameItemPricePerStay',
  aitCheapestItemPrice: 'aitCheapestItemPricePerStay',
  aitRecommendedItemPrice: 'aitRecommendedItemPricePerStay',
  aitAveragePrice: 'aitAveragePricePerStay',
  aitPastSameItemPrice: 'aitPastSameItemPricePerStay',
  aitPastCheapestItemPrice: 'aitPastCheapestItemPricePerStay',
  aitPastRecommendedItemPrice: 'aitPastRecommendedItemPricePerStay',
  aitPastAveragePrice: 'aitPastAveragePricePerStay'
};

const PRICE_TO_DIFF_PER_STAY: Record<PriceField, DiffPerStayField> = {
  aitSameItemPrice: 'aitSameItemDiffPerStay',
  aitCheapestItemPrice: 'aitCheapestItemDiffPerStay',
  aitRecommendedItemPrice: 'aitRecommendedItemDiffPerStay',
  aitAveragePrice: 'aitAverageDiffPerStay',
  aitPastSameItemPrice: 'aitPastSameItemDiffPerStay',
  aitPastCheapestItemPrice: 'aitPastCheapestItemDiffPerStay',
  aitPastRecommendedItemPrice: 'aitPastRecommendedItemDiffPerStay',
  aitPastAveragePrice: 'aitPastAverageDiffPerStay'
};

const PRICE_TO_NAME: Record<PriceField, NameField | null> = {
  aitSameItemPrice: 'aitSameItemName',
  aitCheapestItemPrice: 'aitCheapestItemName',
  aitRecommendedItemPrice: 'aitRecommendedItemName',
  aitAveragePrice: null,
  aitPastSameItemPrice: 'aitPastSameItemName',
  aitPastCheapestItemPrice: 'aitPastCheapestItemName',
  aitPastRecommendedItemPrice: 'aitPastRecommendedItemName',
  aitPastAveragePrice: null
};

const PRICE_TO_HOTEL_ID: Record<PriceField, HotelIdField | null> = {
  aitSameItemPrice: 'aitSameItemHotelId',
  aitCheapestItemPrice: 'aitCheapestItemHotelId',
  aitRecommendedItemPrice: 'aitRecommendedItemHotelId',
  aitAveragePrice: null,
  aitPastSameItemPrice: 'aitPastSameItemHotelId',
  aitPastCheapestItemPrice: 'aitPastCheapestItemHotelId',
  aitPastRecommendedItemPrice: 'aitPastRecommendedItemHotelId',
  aitPastAveragePrice: null
};

export class SimulateBaseRecord {
  id: number;

  row: number;

  simulateBaseFileId: number;

  recordType: SimulateBaseRecordType;

  status: SimulateBaseRecordStatus;

  reservedAt: Moment;

  startAt: Moment;

  endAt: Moment;

  priceWithTax: number;

  priceWithTaxPerStay: number | null = null;

  peopleNum: number;

  roomNum: number;

  stayNum: number | null;

  leadTime: number | null;

  checkin: Moment | null;

  checkout: Moment | null;

  itemName: string;

  traveler: string | null;

  travelerRoman: string | null;

  travelerCode: string | null;

  arranger: string | null;

  arrangerRoman: string | null;

  arrangerCode: string | null;

  message: string | null;

  aitSameItemId: string | null;

  aitSameItemHotelId: number | null;

  aitSameItemPrice: number | null;

  aitSameItemDiff: number | null;

  aitSameItemPricePerStay: number | null = null;

  aitSameItemDiffPerStay: number | null = null;

  aitSameItemName: string | null;

  aitCheapestItemId: string | null;

  aitCheapestItemHotelId: number | null;

  aitCheapestItemPrice: number | null;

  aitCheapestItemDiff: number | null;

  aitCheapestItemPricePerStay: number | null = null;

  aitCheapestItemDiffPerStay: number | null = null;

  aitCheapestItemName: string | null;

  aitRecommendedItemId: string | null;

  aitRecommendedItemHotelId: number | null;

  aitRecommendedItemPrice: number | null;

  aitRecommendedItemDiff: number | null;

  aitRecommendedItemPricePerStay: number | null = null;

  aitRecommendedItemDiffPerStay: number | null = null;

  aitRecommendedItemName: string | null;

  aitAveragePrice: number | null;

  aitAverageDiff: number | null;

  aitAveragePricePerStay: number | null = null;

  aitAverageDiffPerStay: number | null = null;

  aitPastSameItemId: string | null;

  aitPastSameItemHotelId: number | null;

  aitPastSameItemPrice: number | null;

  aitPastSameItemDiff: number | null;

  aitPastSameItemPricePerStay: number | null = null;

  aitPastSameItemDiffPerStay: number | null = null;

  aitPastSameItemName: string | null;

  aitPastCheapestItemId: string | null;

  aitPastCheapestItemHotelId: number | null;

  aitPastCheapestItemPrice: number | null;

  aitPastCheapestItemDiff: number | null;

  aitPastCheapestItemPricePerStay: number | null = null;

  aitPastCheapestItemDiffPerStay: number | null = null;

  aitPastCheapestItemName: string | null;

  aitPastRecommendedItemId: string | null;

  aitPastRecommendedItemHotelId: number | null;

  aitPastRecommendedItemPrice: number | null;

  aitPastRecommendedItemDiff: number | null;

  aitPastRecommendedItemPricePerStay: number | null = null;

  aitPastRecommendedItemDiffPerStay: number | null = null;

  aitPastRecommendedItemName: string | null;

  aitPastAveragePrice: number | null;

  aitPastAverageDiff: number | null;

  aitPastAveragePricePerStay: number | null = null;

  aitPastAverageDiffPerStay: number | null = null;

  csvIndex: number;

  createdAt: Moment;

  updatedAt: Moment;

  constructor(args: SimulateBaseRecordArgs) {
    this.id = args.id;
    this.row = args.row || this.id;
    this.simulateBaseFileId = args.simulate_base_file_id;
    this.recordType = args.record_type;
    this.status = args.status;
    this.reservedAt = moment(args.reserved_at);
    this.startAt = moment(args.start_at);
    this.endAt = moment(args.end_at);
    this.priceWithTax = args.price_with_tax;
    this.peopleNum = args.people_num;
    this.roomNum = args.room_num;
    this.stayNum = args.stay_num ?? null;
    this.leadTime = args.lead_time ?? null;
    this.itemName = args.item_name;
    this.traveler = args.traveler ?? null;
    this.travelerRoman = args.traveler_romen ?? null;
    this.travelerCode = args.traveler_code ?? null;
    this.arranger = args.arranger && args.arranger !== this.traveler ? args.arranger : null;
    this.arrangerRoman = args.arranger_roman ?? null;
    this.arrangerCode = args.arranger_code ?? null;
    this.message = args.message ?? null;
    this.aitSameItemId = args.ait_same_item_id ?? null;
    this.aitSameItemHotelId = args.ait_same_item_hotel_id ?? null;
    this.aitSameItemPrice = args.ait_same_item_price ?? null;
    this.aitSameItemName = args.ait_same_item_name ?? null;
    this.aitCheapestItemId = args.ait_cheapest_item_id ?? null;
    this.aitCheapestItemHotelId = args.ait_cheapest_item_hotel_id ?? null;
    this.aitCheapestItemPrice = args.ait_cheapest_item_price ?? null;
    this.aitCheapestItemName = args.ait_cheapest_item_name ?? null;
    this.aitRecommendedItemId = args.ait_recommended_item_id ?? null;
    this.aitRecommendedItemHotelId = args.ait_recommended_item_hotel_id ?? null;
    this.aitRecommendedItemPrice = args.ait_recommended_item_price ?? null;
    this.aitRecommendedItemName = args.ait_recommended_item_name ?? null;
    this.aitAveragePrice = args.ait_average_price ?? null;
    this.aitPastSameItemId = args.ait_past_same_item_id ?? null;
    this.aitPastSameItemHotelId = args.ait_past_same_item_hotel_id ?? null;
    this.aitPastSameItemPrice = args.ait_past_same_item_price ?? null;
    this.aitPastSameItemName = args.ait_past_same_item_name ?? null;
    this.aitPastCheapestItemId = args.ait_past_cheapest_item_id ?? null;
    this.aitPastCheapestItemHotelId = args.ait_past_cheapest_item_hotel_id ?? null;
    this.aitPastCheapestItemPrice = args.ait_past_cheapest_item_price ?? null;
    this.aitPastCheapestItemName = args.ait_past_cheapest_item_name ?? null;
    this.aitPastRecommendedItemId = args.ait_past_recommended_item_id ?? null;
    this.aitPastRecommendedItemHotelId = args.ait_past_recommended_item_hotel_id ?? null;
    this.aitPastRecommendedItemPrice = args.ait_past_recommended_item_price ?? null;
    this.aitPastRecommendedItemName = args.ait_past_recommended_item_name ?? null;
    this.aitPastAveragePrice = args.ait_past_average_price ?? null;
    this.csvIndex = args.csv_index ?? args.id;
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);

    this.checkin = this.leadTime !== null ? this.updatedAt.clone().add(this.leadTime, 'days') : null;
    this.checkout = this.checkin && this.stayNum !== null ? this.checkin.clone().add(this.stayNum, 'days') : null;

    this.aitSameItemDiff = this.calcDiff(this.aitSameItemPrice);
    this.aitCheapestItemDiff = this.calcDiff(this.aitCheapestItemPrice);
    this.aitRecommendedItemDiff = this.calcDiff(this.aitRecommendedItemPrice);
    this.aitAverageDiff = this.calcDiff(this.aitAveragePrice);
    this.aitPastSameItemDiff = this.calcDiff(this.aitPastSameItemPrice);
    this.aitPastCheapestItemDiff = this.calcDiff(this.aitPastCheapestItemPrice);
    this.aitPastRecommendedItemDiff = this.calcDiff(this.aitPastRecommendedItemPrice);
    this.aitPastAverageDiff = this.calcDiff(this.aitPastAveragePrice);

    this.priceWithTaxPerStay = this.calcPerStay(this.priceWithTax);
    this.aitSameItemPricePerStay = this.calcPerStay(this.aitSameItemPrice);
    this.aitSameItemDiffPerStay = this.calcPerStay(this.aitSameItemDiff);
    this.aitCheapestItemPricePerStay = this.calcPerStay(this.aitCheapestItemPrice);
    this.aitCheapestItemDiffPerStay = this.calcPerStay(this.aitCheapestItemDiff);
    this.aitRecommendedItemPricePerStay = this.calcPerStay(this.aitRecommendedItemPrice);
    this.aitRecommendedItemDiffPerStay = this.calcPerStay(this.aitRecommendedItemDiff);
    this.aitAveragePricePerStay = this.calcPerStay(this.aitAveragePrice);
    this.aitAverageDiffPerStay = this.calcPerStay(this.aitAverageDiff);
    this.aitPastSameItemPricePerStay = this.calcPerStay(this.aitPastSameItemPrice);
    this.aitPastSameItemDiffPerStay = this.calcPerStay(this.aitPastSameItemDiff);
    this.aitPastCheapestItemPricePerStay = this.calcPerStay(this.aitPastCheapestItemPrice);
    this.aitPastCheapestItemDiffPerStay = this.calcPerStay(this.aitPastCheapestItemDiff);
    this.aitPastRecommendedItemPricePerStay = this.calcPerStay(this.aitPastRecommendedItemPrice);
    this.aitPastRecommendedItemDiffPerStay = this.calcPerStay(this.aitPastRecommendedItemDiff);
    this.aitPastAveragePricePerStay = this.calcPerStay(this.aitPastAveragePrice);
    this.aitPastAverageDiffPerStay = this.calcPerStay(this.aitPastAverageDiff);
  }

  getStatus() {
    return STATUSES[this.status];
  }

  submitParams(): SimulateBaseRecordArgs {
    return {
      id: this.id,
      row: this.row,
      simulate_base_file_id: this.simulateBaseFileId,
      record_type: this.recordType,
      status: this.status,
      reserved_at: this.reservedAt.format('L LTS'),
      start_at: this.startAt.format('L LTS'),
      end_at: this.endAt.format('L LTS'),
      price_with_tax: this.priceWithTax,
      people_num: this.peopleNum,
      room_num: this.roomNum,
      stay_num: this.stayNum ?? undefined,
      lead_time: this.leadTime ?? undefined,
      item_name: this.itemName,
      traveler: this.traveler ?? undefined,
      traveler_romen: this.travelerRoman ?? undefined,
      traveler_code: this.travelerCode ?? undefined,
      arranger: this.arranger ?? undefined,
      arranger_roman: this.arrangerRoman ?? undefined,
      arranger_code: this.arrangerCode ?? undefined,
      message: this.message ?? undefined,
      ait_same_item_id: this.aitSameItemId ?? undefined,
      ait_same_item_hotel_id: this.aitSameItemHotelId ?? undefined,
      ait_same_item_price: this.aitSameItemPrice ?? undefined,
      ait_same_item_name: this.aitSameItemName ?? undefined,
      ait_cheapest_item_id: this.aitCheapestItemId ?? undefined,
      ait_cheapest_item_hotel_id: this.aitCheapestItemHotelId ?? undefined,
      ait_cheapest_item_price: this.aitCheapestItemPrice ?? undefined,
      ait_cheapest_item_name: this.aitCheapestItemName ?? undefined,
      ait_recommended_item_id: this.aitRecommendedItemId ?? undefined,
      ait_recommended_item_hotel_id: this.aitRecommendedItemHotelId ?? undefined,
      ait_recommended_item_price: this.aitRecommendedItemPrice ?? undefined,
      ait_recommended_item_name: this.aitRecommendedItemName ?? undefined,
      ait_average_price: this.aitAveragePrice ?? undefined,
      ait_past_same_item_id: this.aitPastSameItemId ?? undefined,
      ait_past_same_item_hotel_id: this.aitPastSameItemHotelId ?? undefined,
      ait_past_same_item_price: this.aitPastSameItemPrice ?? undefined,
      ait_past_same_item_name: this.aitPastSameItemName ?? undefined,
      ait_past_cheapest_item_id: this.aitPastCheapestItemId ?? undefined,
      ait_past_cheapest_item_hotel_id: this.aitPastCheapestItemHotelId ?? undefined,
      ait_past_cheapest_item_price: this.aitPastCheapestItemPrice ?? undefined,
      ait_past_cheapest_item_name: this.aitPastCheapestItemName ?? undefined,
      ait_past_recommended_item_id: this.aitPastRecommendedItemId ?? undefined,
      ait_past_recommended_item_hotel_id: this.aitPastRecommendedItemHotelId ?? undefined,
      ait_past_recommended_item_price: this.aitPastRecommendedItemPrice ?? undefined,
      ait_past_recommended_item_name: this.aitPastRecommendedItemName ?? undefined,
      ait_past_average_price: this.aitPastAveragePrice ?? undefined,
      csv_index: this.csvIndex,
      created_at: this.createdAt.format('L LTS'),
      updated_at: this.updatedAt.format('L LTS')
    };
  }

  priceWithDiff(field: PriceField) {
    const diff = PRICE_TO_DIFF[field];
    const pricePerStay = PRICE_TO_PRICE_PER_STAY[field];
    const diffPerStay = PRICE_TO_DIFF_PER_STAY[field];

    return {
      price: this[field] || 0,
      diff: this[diff] || 0,
      pricePerStay: this[pricePerStay],
      diffPerStay: this[diffPerStay]
    };
  }

  priceWithHotel(field: PriceField) {
    const name = PRICE_TO_NAME[field];
    const hotelId = PRICE_TO_HOTEL_ID[field];
    if (!name || !hotelId) return null;

    return `（${this[hotelId]}：${this[name]}）`;
  }

  calcDiff(value: number | null) {
    if (value === null) return null;

    return value - this.priceWithTax;
  }

  calcPerStay(value: number | null) {
    if (!this.stayNum || this.stayNum < 2 || value === null) return null;

    return Math.round(value / this.stayNum);
  }
}
