import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/components/shared/ui/data_displays/table';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { SendListItem } from '@this/src/domain/send_list/send_list_item';
import type { SendListItemArgs } from '@this/src/domain/send_list/send_list_item';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import type { TabType } from '../todo_list';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import ArrangementOrganizationSelector from '../../shared/arrangement_organization_selector';

type SortKey = 'organization' | 'received_at' | 'started_at';
type SortDirection = 'asc' | 'desc';

interface Response {
  send_list_items: SendListItemArgs[];
  total_count: number;
  total_page: number;
  honorific_options: {
    [key: string]: string;
  };
}

const tab: TabType = 'CREATE_SEND_LIST';

const getDefaultOrganizationIds = (): number[] => {
  const organizationIds = utils.getParam('organization_ids');
  if (!organizationIds) {
    return [];
  }
  return organizationIds.split(',').map((id: string) => parseInt(id, 10));
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'organization') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const SendList = observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [sendListItems, setSendListItems] = useState<SendListItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [honorificOptions, setHonorificOptions] = useState<{ [key: string]: string }>({});
  const [page, setPage] = useState(1);

  const [organizationIds, setOrganizationIds] = useState<number[]>(getDefaultOrganizationIds());
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());

  const fetchItems = useCallback(() => {
    setIsLoading(true);

    const params = {
      page,
      organization_ids: organizationIds.join(','),
      sort_key: sortKey,
      sort_direction: sortDirection
    };

    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=${tab}&${query}`);

    Fetcher.get<Response>('/arrangement/send_list_todos.json', params)
      .then(res => {
        setSendListItems(res.send_list_items.map(args => new SendListItem(args)));
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        setHonorificOptions(res.honorific_options);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .finally(() => setIsLoading(false));
  }, [page, organizationIds, sortKey, sortDirection]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const cancelTicketing = useCallback(
    (item: SendListItem) => {
      setIsLoading(true);
      const params = {
        order_item_id: item.orderItemId
      };
      Fetcher.delete<Response>('/arrangement/ticketings/cancel.json', params).then(() => {
        fetchItems();
      });
    },
    [fetchItems]
  );

  const createSendList = useCallback(() => {
    setIsLoading(true);
    Fetcher.post<Response>('/arrangement/send_list_todos.json', {
      organization_ids: organizationIds.join(',')
    }).then(() => {
      location.href = '/arrangement/send_list2';
    });
  }, [organizationIds]);

  return (
    <>
      <SearchArea>
        <SearchBlock>
          <SearchLabel>法人</SearchLabel>
          <ArrangementOrganizationSelector
            selectedOrganizationIds={organizationIds}
            onSelect={ids => setOrganizationIds(ids)}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>並び順</SearchLabel>
          <select
            value={sortKey}
            onChange={e => setSortKey(e.target.value as SortKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            <option value="organization">法人</option>
          </select>
          <Radio
            checked={sortDirection === 'asc'}
            onChange={() => setSortDirection('asc')}
            style={{ marginRight: '5px' }}
          >
            昇順▲
          </Radio>
          <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
            降順▼
          </Radio>
        </SearchBlock>
      </SearchArea>
      <Box display="flex">
        <Box>未処理件数: {totalCount}件</Box>
        <Box marginLeft="auto">
          <Button onClick={createSendList}>この内容で作成</Button>
        </Box>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>旅程番号</TableCell>
                <TableCell>クライアント名</TableCell>
                <TableCell>ラベル</TableCell>
                <TableCell>担当者名</TableCell>
                <TableCell>郵便番号</TableCell>
                <TableCell>住所</TableCell>
                <TableCell>住所（フロア名）</TableCell>
                <TableCell>座席希望</TableCell>
                <TableCell>メッセージ</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sendListItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell nowrap>{item.tripId}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{honorificOptions[item.honorific]}</TableCell>
                  <TableCell>{item.pic}様</TableCell>
                  <TableCell>{item.postcode}</TableCell>
                  <TableCell>{item.address}</TableCell>
                  <TableCell>{item.subAddress}</TableCell>
                  <TableCell>{item.altShinkansenSeat}</TableCell>
                  <TableCell />
                  <TableCell>
                    <Button onClick={() => cancelTicketing(item)}>発券に戻す</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_: unknown, newPage: number) => setPage(newPage)}
          />
        </>
      )}
    </>
  );
});

export default SendList;
